import React, { useState, useEffect } from "react";
import { Card, CardContent, FormControl, TextField, Box, Autocomplete, Typography, Grid, Paper, Backdrop, CircularProgress, Alert } from "@mui/material";
import Ship from "../../Models/Interfaces/Ship";
import StyledButton from "../../Styles/StyledButton";
import { Business, DirectionsBoat, Home } from "@mui/icons-material";
import CatchRights from "../../Models/Interfaces/CatchRights";
import Title from "../Shared/Title";
import { GetCatchRights } from "../../Services/MillifaersluAPIService";
import { useYear } from "../../Contexts/YearContext";
import { useIsLoadingShips } from "../../Contexts/IsLoadingShipsContext";
import { useIsLoadingCatchRights } from "../../Contexts/IsLoadingCatchRightsContext";
import { useSelectedCatchRights } from "../../Contexts/QuotaSelectionContext";
import { useMobile } from "../../Contexts/MobileContext";
import { useAdmin } from "../../Contexts/AdminProvider";
import Notifier from "../../Models/Interfaces/Notifier";

interface Props {
    companyShips: Ship[];
    allShips: Ship[];
    companyShip: Ship | null;
    toShip: Ship | null;
    setSelectedFromShip: (ship: Ship | null) => void;
    setSelectedToShip: (ship: Ship | null) => void;
    setActiveStep: (step: number) => void;
    activeStep: number;
    setQuotaSelection: React.Dispatch<React.SetStateAction<CatchRights[] | null>>;
    setIsTransferBetweenCompanies: (isTransferBetweenCompanies: boolean) => void;
    notifiers: Notifier[];
    selectedNotifier: Notifier | null;
    setSelectedNotifier: (notifier: Notifier | null) => void;
}

const ShipSelection: React.FC<Props> = (props: Props) => {
    const enginNidurstada = "Engar niðurstöður";
    const [selectedFromShip, setSelectedFromShip] = useState<Ship | null>(props.companyShip);
    const [selectedToShip, setSelectedToShip] = useState<Ship | null>(props.toShip);
    const [fromShipNumber, setFromShipNumber] = useState<number | null>(props.companyShip?.shipNumber || null);
    const [toShipNumber, setToShipNumber] = useState<number | null>(props.toShip?.shipNumber || null);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const { currentYear } = useYear();
    const { isLoadingShips } = useIsLoadingShips();
    const { setIsLoadingCatchRights } = useIsLoadingCatchRights();
    const { setSelectedCatchRights } = useSelectedCatchRights();
    const { isMobile } = useMobile();
    const { isAdmin } = useAdmin();

    useEffect(() => {
        setSelectedFromShip(props.companyShip);
        setSelectedToShip(props.toShip);
        setFromShipNumber(props.companyShip?.shipNumber || null);
        setToShipNumber(props.toShip?.shipNumber || null);
        setButtonDisabled(!(props.companyShip && props.toShip));
    }, [props.companyShip, props.toShip]);

    const fetchCatchRights = async (shipNumber: number) => {
        setIsLoadingCatchRights(true);
        setError(null);
        setSelectedCatchRights([]);
        try {
            const catchRights = await GetCatchRights(shipNumber, currentYear);
            props.setQuotaSelection(catchRights);
        } catch (err) {
            setError('Ekki tókst að sækja aflaheimildir frá miðlara. Vinsamlegast reyndu aftur síðar.');
        } finally {
            setIsLoadingCatchRights(false);
        }
    };

    const handleFromShipChange = (shipNumber: number) => {
        const selectedShip = props.companyShips.find(ship => ship.shipNumber === shipNumber);
        setSelectedFromShip(selectedShip || null);
        setFromShipNumber(shipNumber || null);
        props.setSelectedFromShip(selectedShip || null);
        fetchCatchRights(shipNumber || 0);
        setButtonDisabled(!(selectedShip && selectedToShip));
    };

    const handleToShipChange = (shipNumber: number | undefined) => {
        const selectedShip = props.allShips.find(ship => ship.shipNumber === shipNumber && ship.shipNumber !== selectedFromShip?.shipNumber);
        setSelectedToShip(selectedShip || null);
        setToShipNumber(shipNumber || null);
        props.setSelectedToShip(selectedShip || null);
        setButtonDisabled(!(selectedFromShip && selectedShip));
    };

    const getSelectedShip = (shipNumber: number | null, ships: Ship[]) => {
        if (shipNumber !== null) {
            return ships.find(ship => ship.shipNumber === shipNumber) || null;
        }
        return null;
    };

    const handleButtonClick = () => {
        props.setIsTransferBetweenCompanies(!!props.companyShips.find(ship => ship.shipNumber === toShipNumber));
        props.setActiveStep(1);
    };

    const handleFilterOptions = (options: Ship[], state: { inputValue: string }) => {
        const inputValueLower = state.inputValue.toLowerCase();
        return options.filter(ship =>
            ship.name.toLowerCase().includes(inputValueLower) ||
            ship.shipNumber.toString().toLowerCase().includes(inputValueLower) ||
            ship.operatorGroup.toLowerCase().includes(inputValueLower)
        );
    };

    return (
        <Card
            variant="outlined"
            sx={{
                padding: isMobile ? '5px' : '20px',
                paddingBottom: isMobile ? '15px' : '20px',
                backgroundColor: 'white',
                boxShadow: 3,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {isLoadingShips && (
                <Backdrop style={{ zIndex: 1300, color: '#fff' }} open={isLoadingShips}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Title title="Uppfletting" />
            <CardContent sx={{ background: 'white', flexGrow: 1, display: 'flex', flexDirection: 'column', padding: '1px', paddingTop: '10px', overflow: 'auto' }}>
                {error && <Alert severity="error" sx={{ marginBottom: '10px' }}>{error}</Alert>}
                <Box sx={{ paddingLeft: isMobile ? 0 : '1px', paddingRight: isMobile ? 0 : '1px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: '0px' }}>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        padding: isMobile ? '15px' : '20px',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                                    >
                                        Seljandi
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <DirectionsBoat fontSize="small" sx={{ marginRight: '8px' }} />
                                        Skip: {selectedFromShip?.name} {selectedFromShip?.shipNumber}
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Home fontSize="small" sx={{ marginRight: '8px' }} />
                                        Heimahöfn: {selectedFromShip?.homePort}
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Business fontSize="small" sx={{ marginRight: '8px' }} />
                                        Rekstraraðili: {selectedFromShip?.operatorName || ''}
                                        {selectedFromShip?.operatorId ? ` (${selectedFromShip?.operatorId})` : ''}
                                    </Typography>
                                </Paper>
                                <Autocomplete
                                    autoHighlight
                                    id="fromShip"
                                    options={props.companyShips}
                                    getOptionLabel={(ship) => `${ship.shipNumber} ${ship.name} (${ship.operatorGroup})`}
                                    onChange={(event, value) => handleFromShipChange(value?.shipNumber || 0)}
                                    value={getSelectedShip(fromShipNumber, props.companyShips)}
                                    renderInput={(params) => <TextField {...params} label="Skip" variant="outlined" />}
                                    filterOptions={(options, state) => {
                                        const inputValue = state.inputValue.toLowerCase();
                                        return handleFilterOptions(options, { inputValue });
                                    }}
                                    noOptionsText={enginNidurstada}
                                    style={{ paddingTop: '10px' }}
                                    defaultValue={selectedFromShip || null}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        padding: isMobile ? '15px' : '20px',
                                        backgroundColor: 'white'
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        sx={{ fontWeight: 'bold', marginBottom: '10px' }}
                                    >
                                        Kaupandi
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <DirectionsBoat fontSize="small" sx={{ marginRight: '8px' }} />
                                        Skip: {selectedToShip?.name} {selectedToShip?.shipNumber}
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Home fontSize="small" sx={{ marginRight: '8px' }} />
                                        Heimahöfn: {selectedToShip?.homePort}
                                    </Typography>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <Business fontSize="small" sx={{ marginRight: '8px' }} />
                                        Rekstraraðili: {selectedToShip?.operatorName || ''}
                                        {selectedToShip?.operatorId ? ` (${selectedToShip?.operatorId})` : ''}
                                    </Typography>
                                </Paper>
                                <Autocomplete
                                    autoHighlight
                                    id="toShip"
                                    options={props.allShips}
                                    getOptionLabel={(ship) => `${ship.shipNumber} ${ship.name} (${ship.operatorGroup})`}
                                    onChange={(event, value) => handleToShipChange(value?.shipNumber)}
                                    value={getSelectedShip(toShipNumber, props.allShips)}
                                    renderInput={(params) => <TextField {...params} label="Skip" variant="outlined" />}
                                    filterOptions={handleFilterOptions}
                                    noOptionsText={enginNidurstada}
                                    style={{ paddingTop: '10px' }}
                                />
                            </FormControl>
                        </Grid>
                        {/* Only show notifier selection if the user is admin */}
                        {isAdmin && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        autoHighlight
                                        id="notifier"
                                        options={props.notifiers}
                                        getOptionLabel={(notifier) => `${notifier.notifierId} - ${notifier.notifierName}`}
                                        onChange={(event, value) => props.setSelectedNotifier(value)}
                                        value={props.selectedNotifier}
                                        renderInput={(params) => <TextField {...params} label="Tilkynnandi" variant="outlined" />}
                                        noOptionsText="Engir tilkynendur fundust."
                                    />
                                </FormControl>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}>
                <StyledButton
                    disabled={buttonDisabled || !!error}
                    onClick={() => {
                        handleButtonClick();
                    }}
                    variant="contained"
                >
                    Áfram
                </StyledButton>
            </Box>
        </Card>
    );
};

export default ShipSelection;
