import React, { useEffect, useState } from "react";
import {
    Card, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions,
    Box, Alert, CircularProgress, Backdrop
} from "@mui/material";
import Paper, { PaperProps } from '@mui/material/Paper';
import Ship from "../../Models/Interfaces/Ship";
import StyledButton from "../../Styles/StyledButton";
import TransferHead from "../../Models/Interfaces/TransferHead";
import Transfer from "../../Models/Interfaces/Transfer";
import { TransferQuota, Verification } from "../../Services/MillifaersluAPIService";
import Draggable from "react-draggable";
import Title from "../Shared/Title";
import ShipInfoGrid from "../Shared/ShipInfoGrid";
import TransferTable from "../Shared/TransferTable";
import { useSelectedCatchRights } from "../../Contexts/QuotaSelectionContext";
import { useMobile } from "../../Contexts/MobileContext";
import { useYear } from '../../Contexts/YearContext';
import Notifier from "../../Models/Interfaces/Notifier";
import NotifierComponent from "../Shared/Notifier";

interface Props {
    companyShip: Ship | null;
    shipTo: Ship | null;
    activeStep: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    setReferenceNumber: React.Dispatch<React.SetStateAction<number>>;
    setInvoiceFile: React.Dispatch<React.SetStateAction<string>>;
    selectedNotifier: Notifier | null;
}

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const ValidateTransfer: React.FC<Props> = ({
    companyShip,
    shipTo,
    setActiveStep,
    activeStep,
    setReferenceNumber,
    setInvoiceFile,
    selectedNotifier
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [transferHead, setTransferHead] = useState<TransferHead | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedCatchRights } = useSelectedCatchRights();
    const { isMobile } = useMobile();
    const { currentYear } = useYear();

    const ValidateTransferFunc = async () => {
        setIsLoading(true);
        setError(null);
        if (!companyShip || !shipTo || !selectedCatchRights) {
            setIsLoading(false);
            return;
        }
        const transferHead: TransferHead = {
            shipFrom: companyShip.shipNumber,
            shipTo: shipTo.shipNumber,
            shipToCompany: shipTo.operatorId,
            transfers: CatchRightsToTransfers(),
            transfer_current_year: currentYear,
            notifier: selectedNotifier ?? { notifierId: "", notifierName: "" }
        };
        setTransferHead(transferHead);
        try {
            const response = await Verification(transferHead);
            HandleValidateResponse(response);
        } catch (err) {
            setError('Ekki tókst að staðfesta hvort hægt sé að millifæra. Vinsamlegast reyndu aftur síðar.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const ValidateOnLoad = async () => {
            await ValidateTransferFunc();
        };
        ValidateOnLoad();
        // eslint-disable-next-line
    }, []);

    function CatchRightsToTransfers(): Transfer[] {
        const transfers: Transfer[] = [];
        selectedCatchRights?.forEach((right) => {
            transfers.push({
                quotaType: right.quotaType,
                quantity: right.quantity,
                price: right.kr || 0
            });
        });
        return transfers;
    }

    const HandleValidateResponse = (response: boolean | string) => {
        if (typeof response === 'string') {
            setError(response);
        } else {
            setError(null);
        }
    };

    const PostTransfer = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const reference = await TransferQuota(transferHead!);
            setReferenceNumber(reference.referenceNumber);
            setInvoiceFile(reference.invoiceFile);
            setActiveStep(activeStep + 1);
        } catch (err) {
            setError('Ekki tókst að ljúka millifærslu. Vinsamlegast reyndu aftur síðar.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card
            variant="outlined"
            sx={{
                padding: isMobile ? '5px' : '20px',
                paddingBottom: isMobile ? '15px' : '20px',
                backgroundColor: 'white',
                boxShadow: 3,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {isLoading && (
                <Backdrop style={{ zIndex: 1300, color: '#fff' }} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Title title="Millifæra" />
            <CardContent
                sx={{
                    background: 'white',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '1px',
                    paddingTop: '10px',
                    overflow: 'auto'
                }}
            >
                {error && <Alert severity="error" sx={{ marginBottom: '10px' }}>{error}</Alert>}
                <Dialog
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ style: { zIndex: 1300 } }}
                >
                    <DialogTitle sx={{ backgroundColor: 'white', color: '#333', padding: '15px' }}>
                        Staðfesting
                    </DialogTitle>
                    <DialogContent sx={{ backgroundColor: 'white', padding: '15px' }}>
                        <Typography>Millifærsla stenst kröfur, viltu millifæra?</Typography>
                    </DialogContent>
                    <DialogActions sx={{ backgroundColor: '#fafafa', padding: '15px' }}>
                        <StyledButton
                            onClick={() => setOpenModal(false)}
                            sx={{ marginRight: '10px' }}
                        >
                            Hætta við
                        </StyledButton>
                        <StyledButton
                            onClick={() => {
                                PostTransfer();
                                setOpenModal(false);
                            }}
                            color="primary"
                            disabled={!!error || isLoading}
                        >
                            Millifæra
                        </StyledButton>
                    </DialogActions>
                </Dialog>
                <Box sx={{ paddingLeft: isMobile ? 0 : '1px', paddingRight: isMobile ? 0 : '1px' }}>
                    <ShipInfoGrid companyShip={companyShip} shipTo={shipTo} />
                    {selectedNotifier && <NotifierComponent selectedNotifier={selectedNotifier} />}
                    <TransferTable selectedCatchRights={selectedCatchRights} />
                </Box>
            </CardContent>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: 'auto',
                    paddingTop: '20px',
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1200
                }}
            >
                <StyledButton onClick={() => setActiveStep(activeStep - 1)}>Til baka</StyledButton>
                <StyledButton
                    disabled={isLoading || !!error}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                >
                    Millifæra
                </StyledButton>
            </Box>
        </Card>
    );
};

export default ValidateTransfer;
