import React from 'react';
import { Box, Typography } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';
import Notifier from '../../Models/Interfaces/Notifier';

interface Props {
  selectedNotifier: Notifier;
}

const NotifierComponent: React.FC<Props> = ({ selectedNotifier }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff3cd',
        padding: '12px',
        borderRadius: '5px',
        marginY: '15px',
        boxShadow: 2
      }}
    >
      <CampaignIcon sx={{ marginRight: '10px', color: '#856404' }} />
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#856404' }}>
        Tilkynnandi: {selectedNotifier.notifierName} ({selectedNotifier.notifierId})
      </Typography>
    </Box>
  );
};

export default NotifierComponent;
