import React, { useState } from 'react';
import { Card, Typography, Box } from '@mui/material';
import Ship from '../../Models/Interfaces/Ship';
import StyledButton from '../../Styles/StyledButton';
import Title from '../Shared/Title';
import TransferTable from '../Shared/TransferTable';
import ShipInfoGrid from '../Shared/ShipInfoGrid';
import { useSelectedCatchRights } from '../../Contexts/QuotaSelectionContext';
import { useMobile } from "../../Contexts/MobileContext";
import { downloadBase64File } from '../../Utils/DownloadBase64File';
import DownloadIcon from '@mui/icons-material/Download';
import SuccessMessage from '../Shared/SuccessMessage';
import InfoIcon from '@mui/icons-material/Info';
import Notifier from '../../Models/Interfaces/Notifier';
import NotifierComponent from '../Shared/Notifier';

interface Props {
  referenceNumber: number;
  invoiceFile: string;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setSelectedFromShip: React.Dispatch<React.SetStateAction<Ship | null>>;
  setSelectedToShip: React.Dispatch<React.SetStateAction<Ship | null>>;
  companyShip: Ship | null;
  shipTo: Ship | null;
  selectedNotifier: Notifier | null;
}

const Reference: React.FC<Props> = ({
  referenceNumber,
  invoiceFile,
  setActiveStep,
  companyShip,
  shipTo,
  setSelectedFromShip,
  setSelectedToShip,
  selectedNotifier,
}) => {
  const { selectedCatchRights, setSelectedCatchRights } = useSelectedCatchRights();
  const { isMobile } = useMobile();
  const [showSuccessMessage, setShowSuccessMessage] = useState(true);

  const clearSelectedData = () => {
    setSelectedCatchRights([]);
    setSelectedFromShip(null);
    setSelectedToShip(null);
    setShowSuccessMessage(false);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        padding: isMobile ? '5px' : '20px',
        paddingBottom: isMobile ? '15px' : '20px',
        backgroundColor: 'white',
        boxShadow: 3,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {showSuccessMessage && (
        <SuccessMessage referenceNumber={referenceNumber} onClose={handleCloseSuccessMessage} />
      )}

      <Title title={`Tilvísunarnúmer: ${referenceNumber}`} color="green" />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: isMobile ? '0px' : '10px',
          marginBottom: '10px',
          backgroundColor: '#f0f8ff',
          padding: '10px',
          borderRadius: '4px',
          textAlign: 'center'
        }}
      >
        <InfoIcon sx={{ marginRight: '8px', color: '#1976d2' }} />
        <Typography
          sx={{
            color: '#212529',
            fontWeight: 'bold',
            fontSize: isMobile ? '14px' : '18px',
          }}
        >
          Hægt er að nálgast kvittun í pósthólfi Island.is.
        </Typography>
      </Box>
      <Box sx={{ paddingLeft: isMobile ? 0 : '1px', paddingRight: isMobile ? 0 : '1px' }}>
        <ShipInfoGrid companyShip={companyShip} shipTo={shipTo} />
        {selectedNotifier && <NotifierComponent selectedNotifier={selectedNotifier} />}
        <TransferTable selectedCatchRights={selectedCatchRights} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 'auto',
          paddingTop: '20px',
          position: 'sticky',
          bottom: 0,
          zIndex: 1200
        }}
      >
        <StyledButton
          onClick={() => {
            setActiveStep(0);
            clearSelectedData();
          }}
        >
          Til baka í skipaval
        </StyledButton>
        <StyledButton
          startIcon={<DownloadIcon />}
          customcolor="green"
          onClick={() => {
            downloadBase64File(invoiceFile, `invoice_${referenceNumber}.pdf`);
          }}
        >
          Sækja kvittun
        </StyledButton>
      </Box>
    </Card>
  );
};

export default Reference;
